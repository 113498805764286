<template>
  <nav class="navbar navbar-dark mb-3 shadow-lg">
    <a class="navbar-brand mt-1 mx-4" href="#">
      <img width="auto" height="55" src="https://i.imgur.com/QEZMHBy.png">
    </a>
    <button class="navbar-toggler mx-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-5" id="navbarTogglerDemo02">
        <ul class="navbar-nav ms-auto">
            <li class="nav-item">
                <a class="nav-link" href="">Sign up for updizzles</a>
            </li>
        </ul>
    </div>
  </nav>
  <div class="center-block p-4">
    <div class="App-logo-sm d-block d-sm-none">
      <img class="img-fluid" src="https://i.imgur.com/3H4dOSt.png">
    </div>
    <div class="App-logo-lg d-none d-sm-block">
      <img class="img-fluid" src="https://i.imgur.com/3H4dOSt.png">
    </div>
  </div>
  <h1><strong>Cannabiz: The Board Game!</strong></h1>
  <h4 class="mx-3">
    <strong>
      a marijuana metropolis to grow your entrepreneurial roots & reach for success.
    </strong>
  </h4>
  <input type="email" class="my-2" placeholder="four-twenty@blaze.it" style="text-align: center">
  <br>
  <div class="btn btn-success" href="">sign up for updizzles</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.greeen {
  color:green;
}
.navbar-dark .navbar-toggler-icon{
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Cannabis_leaf.svg/1024px-Cannabis_leaf.svg.png?20220212181746)!important;
}
.navbar-dark {
  background: #080a55;
}
.navbar-brand {
  color:#d0e8ce !important;
}
.nav-link {
  color:white !important;
}

body {
 background-image: 
  radial-gradient(rgb(140, 189, 133), transparent 90% ),
  /* radial-gradient(69% 69% at center, transparent 69%, rgb(140, 189, 133)), */
  url("https://i.imgur.com/66REFA0.jpg");
 background-color: rgb(140, 189, 133);
 background-position: center; 
 background-repeat: no-repeat; 
 background-size: cover; 
 width: 100vw; height: 100vh;
}

a {
  color: green;
}
h1 {
  text-shadow: 2px 2px #ddd;
  color:black;
}
h4 {
  text-shadow: 2px 2px #ddd;
  color:black;
}

.center-block {
 
 display: flex; flex-direction: row;
 justify-content: center; align-items: center;
}

.App-logo-sm {
  animation: App-logo-spin infinite 15s linear;
  height: 300px;
  width: 300px;
  pointer-events: none;
}

.App-logo-lg {
  animation: App-logo-spin infinite 15s linear;
  height: 500px;
  width: 500px;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.box {
  -webkit-mask:radial-gradient(circle at 50% 50%, transparent , #fff 100%);
          mask:radial-gradient(circle at 50% 50%, transparent , #fff 100%);
}


</style>
